import Resources from 'components/data/Resources/components';
import { TableColumn } from 'components/bricks/types/tableColumn.type';
import ComponentStore from 'components/data/ComponentStore';
import cloneDeep from 'helpers/cloneDeep';
import { BrickChannel, BrickSocialPlatform, BrickSubType } from '../types/brick.type';
import { IEditorBlock } from '../types/editorBlock.type';
import { BricksComponentStore, Filters } from '../types/bricksComponentStore.type';

export interface CustomerConfig {
    availableBricks?: ConfigAvailableBricks[];
    customColumns?: {
        view: string[];
        columns: TableColumn[];
    };
    brickTabs?: any;
    campaignTabs?: ConfigCampaignTab[];
    settings?: ConfigSettings;
    campaignSettings?: any;
    interfaceSetup?: ConfigInterfaceSetup[];
    defaultData?: any;
}

export interface ConfigCampaignTab {
    key: string;
    title: string;
    icon?: string;
    defaultView?: string;
    columns?: ConfigCampaignTabColumn[];
    canChangeColumns?: boolean;
    rights?: string[];
    filters?: Filters;
}
interface ConfigCampaignTabColumn {
    identifier: string;
}

interface ConfigAvailableBricks {
    channel?: BrickChannel;
    platform?: BrickSocialPlatform;
    subType?: BrickSubType;
}

interface ConfigSettings {
    enableRequest?: boolean;
    showSettingsIcon?: boolean;
}

interface ConfigInterfaceSetup {
    subTypes?: BrickSubType[];
    tab?: string;
    newTab?: boolean;
    afterTab?: string;
    removeFromInterfaceSetup?: string[];
    addToInterfaceSetup?: IEditorBlock[];
}

const getCustomerConfig = (): CustomerConfig => {
    // Set this to false to use the test config (e.g. on localhost). If true, it uses the config from the resources
    const useSetupConfig = true;

    if (!useSetupConfig) return customerConfig;

    // Get the account wide config from the resources, then overwrite it with the campaignFormat specific config
    const config: CustomerConfig = cloneDeep(Resources.get('setup_bricks') ?? {});
    const campaignFormatConfig: CustomerConfig = cloneDeep((ComponentStore.get('Bricks') as BricksComponentStore)?.campaignFormatConfig);

    if (campaignFormatConfig && Object.keys(campaignFormatConfig).length > 0) {
        Object.keys(campaignFormatConfig).forEach((key) => {
            config[key] = campaignFormatConfig[key];
        });
    }

    // TEMPORARY: Only show finished bricks
    // config.availableBricks = [{ channel: 'assets' }, { channel: 'social', platform: 'meta' }, { channel: 'display', platform: 'cm360' }];

    return config;
};

const customerConfig: CustomerConfig = {
    availableBricks: [
        { channel: 'assets' },
        { channel: 'social', platform: 'multi' },
        { channel: 'social', platform: 'meta' },
        { channel: 'social', platform: 'tiktok' }
    ],
    customColumns: {
        view: ['create'],
        columns: [
            {
                identifier: 'budget',
                type: 'text',
                title: 'Budget',
                model: 'data.budget',
                icon: 'euro'
            }
        ]
    },
    brickTabs: [
        {
            subTypes: ['single_asset'],
            key: 'brickTab',
            brickTab: true,
            title: 'Custom tab',
            type: 'multiInput',
            afterTab: 'briefing',
            items: [
                {
                    title: 'Trafficking setup',
                    path: 'publish',
                    items: [
                        {
                            type: 'subSection',
                            opened: true,
                            title: 'Ad Details',
                            items: [
                                {
                                    type: 'text',
                                    model: 'data.publish.placement',
                                    tooltip: 'Please insert placement name.',
                                    placeholder: "e.g. 'Klima'",
                                    label: 'Placement name'
                                },
                                {
                                    type: 'select',
                                    model: 'data.publish.targeting',
                                    label: 'Targeting',
                                    options: {
                                        '1825': '18-65',
                                        '': 'Select...'
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    defaultData: [
        {
            subTypes: ['single_asset'],
            data: {
                title: 'Default title from customer config'
            }
        }
    ],
    interfaceSetup: [
        {
            subTypes: ['meta_campaign'],
            tab: 'settings',
            removeFromInterfaceSetup: ['single_asset-descriptionz'],
            addToInterfaceSetup: []
        }
    ]
};

export default getCustomerConfig;
